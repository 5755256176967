const moment = require('moment');
const momentZ = require('moment-timezone');

const formatDuration = (minutes) => {
  const duration = moment.duration(minutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.asMinutes()) % 60;

  return `${hours} hours ${mins} minutes`;
}

const formatSpanInDays = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);

  return endDate.diff(startDate, 'days') + ' days';
}

// American flag for American date format 
const formattedDate = (d, American) => {
  const currentDate = d ? new Date(d) : new Date();

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');

  const fd = American ? `${month}/${day}/${year}` : `${year}-${month}-${day}`;
  return fd;
}

// l for lowercase
const formatTimeWithZone = (t, l) => {
  const [dt,tm] = t.split('T');
  const [timestamp] = tm.split('-');

  const formattedTime = l ? momentZ(dt + 'T' + timestamp).format('h:mm a z') : momentZ(dt + 'T' + timestamp).format('h:mm A z');
  return formattedTime;
}

function generateRandomString(l = 6) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let charactersLength = characters.length;
  for (let i = 0; i < l; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function replaceWords(inputString, replacements) {
  replacements.forEach(replacement => {
    const [oldWord, newWord] = replacement;
    const regex = new RegExp(`\\b${oldWord}\\b`, 'gi');
    inputString = inputString.replace(regex, newWord);
  });
  return inputString;
}

export {
  formatDuration,
  formatSpanInDays,
  formattedDate,
  formatTimeWithZone,
  generateRandomString,
  replaceWords
}
