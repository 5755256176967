import '../stylesheets/SummaryModules.scss';
import eventLogo from '../../assets/default/images/event-logo.png';
import { useEffect, useCallback, useState } from 'react';
import {getSummaryModules, getSummaryModulesByDemo} from '../../utils/fetch/llmFetching';
import {summaryRangeStart, summaryRangeEnd} from "../../utils/constants";
import {getLocal} from '../../utils/dataFetching';
import { useErrorBoundary } from "react-error-boundary";

const SummaryModules = ({ datestart, dateend }) => {
  const { showBoundary } = useErrorBoundary();

  const [demo, setDemo] = useState(getLocal('demo'));
  const [modules, setModules] = useState(null);

  const getData = useCallback(async (demoId) => {
    try {
      const response = await getSummaryModulesByDemo(demoId);

      setModules(response);
    } catch (e) {
      return null;
      showBoundary(e);
    }
  }, []);

  useEffect(() => {
    getData(demo._id);
  }, [demo, getData]);

  return (
    <div className="summaries-container">
      <div className="logo-container"></div>
      <h3>Session Summaries</h3>
      <div className="summaries-scrollable">
        {modules && modules.map(module => <button key={module._id} type="button" onClick={() => window.location = `/${demo.slug}/session-summaries/${module._id}?datestart=${datestart}&dateend=${dateend}`}>{module.title}</button>)}
      </div>
    </div>
  );
};

export default SummaryModules;
