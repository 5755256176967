import axios from "axios";
import { setLocal } from "../dataFetching";

const trackStreamDuration = async (
    sessionId,
    startTime,
    endTime,
    vendor,
    isStart,
    trackingId
) => {
    try {
        const data = {
            sessionId,
            startTime,
            endTime,
            vendor,
            isStart,
            trackingId
        };

        const response = await axios.post(`${process.env.REACT_APP_API}/api/tracking/streams`, data);

        const { data: resData } = response;
        const { state } = resData;

        switch(state) {
            case 'tracking':
                setLocal('stream_tracking_id', resData.tracking_id);
                break;
            case 'end':
                setLocal('stream_tracking_id', '');
                break;
            default:
                console.log('unknown tracking state reached');
                return;
        }

        return resData;

    } catch (e) {
      console.log(e);
      throw new Error('An error occurred while getting LLM Response');
    }
  }
  
  
  export { trackStreamDuration };