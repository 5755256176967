import {getEvalReponse} from "../../utils/fetch/llmFetching";

const getEvaluation = async (sessionId, modId, results)=> {
  try {
    let summary = results;

    if (summary === '') {
      const response = await getEvalReponse(sessionId, modId);
      if (response?.data?.results) {
        summary = response.data.results;
      }
    }

    return summary;

  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting LLM Response');
  }
}


export { getEvaluation }
