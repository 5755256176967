import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { generateRandomString } from '../../utils/helpers';
import {emptyLocal} from "../../utils/dataFetching";

const Tester = () => {
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {

        // Empty local storage
        emptyLocal();
        // Clear session storage
        sessionStorage.clear();

        const emails = [generateRandomString() + '@' + generateRandomString() + '.com'];
        const data = { emails };

        const response = await axios.post(`${process.env.REACT_APP_API}/access-codes`, data);
        setResponseData(response.data); // Set the response data to state
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after API call is finished
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <div>
          <h1>New Session Ready</h1>
          <h2>Access Code: {responseData.accessCodes[0].code}</h2>
          <h2><a href={'/?key=' + responseData.accessCodes[0].code}>New Session</a></h2>
        </div>
      )}
    </div>
  );
};

export default Tester;