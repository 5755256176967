import axios from "axios";
import {getLocal, setLocal} from "../dataFetching";

const getHistory = async (key)=> {
  try {
    const sessionId = getLocal('session_id');

    if (!sessionId) {
      setLocal('auth_token', '');
      window.location.reload();
      throw new Error('Session ID not found');
    }

    const response = await axios.get(`${process.env.REACT_APP_API}/api/sessions/${sessionId}`);
    const hist = [];

    response.data.session?.displayContents?.forEach(c => {
      hist.push({text:c.human, sender: "Human", conversation_id: c.conversation_id || ''});
      hist.push({text:c.ai, sender: "AI", conversation_id: c.conversation_id || ''});
    });

    console.log(hist);

    if (!hist) {
      setLocal('session_id', '');
      setLocal('auth_token', '');
      window.location.reload();
    }

    return hist;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while getting history');
  }
}

const clearHistory = async ()=> {
  try {
    const sessionId = getLocal('session_id');

    if (!sessionId) {
      throw new Error('Session ID not found');
    }

    const response = await axios.delete(`${process.env.REACT_APP_API}/clear/` + sessionId);

    console.log(response);
    window.location.reload();
    return response;
  } catch (e) {
    console.log(e);
    throw new Error('An error occurred while clearing history');
  }
}


export { getHistory, clearHistory }
