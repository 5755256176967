// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe#webpack-dev-server-client-overlay {
  display: none; }

.ErrorBoundary {
  background-color: #2f3032;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; }
  .ErrorBoundary .message {
    margin: 20px; }
  .ErrorBoundary .retry-button {
    color: #4488eb;
    border: none;
    background: none;
    margin-top: 10px; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/ErrorBoundary.scss"],"names":[],"mappings":"AACA;EACI,aAAa,EAAA;;AAGjB;EACI,yBAAyB;EACzB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY,EAAA;EAPhB;IAUQ,YAAY,EAAA;EAVpB;IAcQ,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,gBAAgB,EAAA","sourcesContent":["// We have to manually hide the red text error overlay\niframe#webpack-dev-server-client-overlay {\n    display: none;\n}\n\n.ErrorBoundary {\n    background-color: #2f3032;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    color: white;\n\n    .message {\n        margin: 20px;\n    }\n\n    .retry-button {\n        color: #4488eb;\n        border: none;\n        background: none;\n        margin-top: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
