import AvatarVideo from "../general/AvatarVideo";

import '../stylesheets/RolePlay.scss';
import Header from "../general/Header";
import {sessionCreate} from "../../utils/session/session";
import { setLocal } from '../../utils/dataFetching';
import { useEffect, useCallback, useState } from 'react';
import { useErrorBoundary } from "react-error-boundary";

const RolePlay = ({scenario, audioRef}) => {
  const { showBoundary } = useErrorBoundary();

  const [rpSessionId, setRpSessionId] = useState('');
  const rpModId = scenario?._id;
  
  // Avatar Settings
  setLocal("avatar_url", process.env.REACT_APP_ASSETS_URL + '/_avatars/' + scenario?.avatar + '/image.png');
  setLocal("avatar_audio", scenario?.avatarVoice);

  const getSession = useCallback(async () => {
    try {
      if (!rpSessionId) {
        const sessionRes = await sessionCreate(rpModId);

        if (sessionRes.data?.session) {
          setRpSessionId(sessionRes.data.session._id);
          setLocal('rp_'+ scenario?.slug + '_session_id', sessionRes.data.session._id);
        }
      }
    } catch (e) {
      showBoundary(e);
    }

  }, [rpModId, rpSessionId, scenario]); 

  useEffect(() => {
    getSession();
  }, [getSession]);


  return (<div className="role-play-container d-flex flex-column justify-content-start align-items-center">
      <Header />
      {rpSessionId ? (
        <AvatarVideo sessionId={rpSessionId} modId={rpModId} scenario={scenario} audioRef={audioRef} />
      ) : (
        <div></div>
      )}
      
      {/*<SwitchMode step={step} handleSwitchMode={handleSwitchMode} />*/}
    </div>
  );
}

export default RolePlay;
