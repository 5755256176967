import '../stylesheets/VoiceTest.scss';
import {getAudioResponse} from "../../utils/fetch/speechFetching";
import {getLocal, setLocal} from "../../utils/dataFetching";
import {promptTesterModId, replaceList} from "../../utils/constants";
import {replaceWords} from "../../utils/helpers";
import {sessionCreate, sessionList} from "../../utils/session/session";
import { useState, useRef, useCallback, useEffect } from 'react';

const VoiceTest = () => {
  const [response, setResponse] = useState('');
  const [audioURL, setAudioURL] = useState('');

  const promptSessionIdRef = useRef(getLocal('prompt_session_id'));

  const getSession = useCallback(async () => {

    try {
      if (!promptSessionIdRef.current) {

        // Check if session exists in DB
        const sessionResList = await sessionList();
        sessionResList.data.sessions?.forEach(s => {
          if (s.module === promptTesterModId) {
            promptSessionIdRef.current = s._id;
            setLocal('prompt_session_id', promptSessionIdRef.current);
          }
        });

        // If empty, create one
        if (!promptSessionIdRef.current) {
          const sessionRes = await sessionCreate(promptTesterModId);
          if (sessionRes.data?.session) {
            promptSessionIdRef.current = sessionRes.data.session._id;
            setLocal('prompt_session_id', promptSessionIdRef.current);
          }
        }
      }
    } catch (e) {
      console.error('Error fetching session:', e);
    }

    console.log("promptSessionIdRef");
    console.log(promptSessionIdRef.current);
  }, []);

  useEffect(() => {
    getSession();
  }, [getSession]);


  const processForm = async (event) => {
    event.preventDefault();
    setAudioURL('');
    setResponse('');
    
    const voice = event.target.elements['voice-test-voice'].value;
    const textStr = event.target.elements['voice-test-text'].value;

    try {
      setResponse('Please wait...');

      // let updateTextStr = replaceWords(textStr, replaceList);
      // console.log("updateTextStr");
      // console.log(updateTextStr);
      
      const response = await getAudioResponse(textStr, promptSessionIdRef, voice);
      console.log(response);

      if (response && response.data) {
        setResponse(response.data.filepath);
        setAudioURL(response.data.filepath);

      } else {
        setResponse('A malformed response was received');
      }
    } catch (error) {
      setResponse('An error occurred while getting Response');
      console.log(error);
    }
  };

  return (
    <div className="voice-test-container">
      <h1>Voice Test</h1>
      <div className="voice-test-form-container">
        <form className="voice-test-form" onSubmit={processForm}>
          <div className="form-field">
            <label htmlFor="voice-test-voice">Voice:</label>
            <select id="voice-test-voice">
              <option value="en-US-AvaNeural">Ava</option>
              <option value="en-US-EmmaNeural">Emma</option>
              <option value="en-US-EricNeural">Eric</option>
              <option value="en-US-SteffanNeural">Steffan</option>
              <option value="en-US-AndrewMultilingualNeural">Andrew</option>
              <option value="en-US-BrianMultilingualNeural">Brian</option>
            </select>
          </div>
          <div className="form-field">
            <label htmlFor="voice-test-text">Text:</label>
            <textarea id="voice-test-text"></textarea>
          </div>
          <div className="form-actions">
            <button type="submit">Submit</button>
          </div>
        </form>
        <div className="voice-test-response">
          <div>Response:</div>
          {audioURL ? (
               <div>
               <audio controls>
                 <source src={audioURL} type="audio/mp3" />
                 Your browser does not support the audio element.
               </audio>
             </div>
          ) : (
            <div className="prompt-test-response-scrollable">{response}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VoiceTest;
