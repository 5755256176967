import { sessionList } from "../../utils/session/session";
import { useEffect, useCallback, useState } from 'react';

const ViewLog = () => {
    const [sessionListRes, setSessionListRes] = useState([]);

    const getLog = useCallback(async () => {
        console.log("Get Session");
        try {
            const response = await sessionList();
            setSessionListRes(response.data.sessions);

            console.log(response.data.sessions);
        } catch (e) {
            console.error('Error history:', e);
        }

        
    }, []);

  useEffect(() => {
    getLog();
  }, [getLog]);

  return (
    <div className="view-log-container">
      <h1>View Log</h1>
      <ul>
        {sessionListRes.filter((session) => session.contents.length > 0).map((session) => (
          <li key={session._id}>
            <h2>Session ID: {session._id}</h2>
            <p>Updated At: {new Date(session.updatedAt).toLocaleString()}</p>
            <h3>Contents:</h3>
            <ul>
              {session.contents.map((content, index) => (
                <li key={index}>
                  <p>Human: {content.human}</p>
                  <p>AI: {content.ai}</p>
                </li>
              ))}
            </ul>
          </li>

        ))}
      </ul>
    </div>
  );
};

export default ViewLog;
