// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voice-test-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%; }
  .voice-test-container h1 {
    margin: 1rem; }
  .voice-test-container .voice-test-form-container {
    display: flex;
    flex: 1 1;
    overflow: hidden; }
  .voice-test-container .voice-test-form {
    flex: 1 1;
    margin: 1rem;
    overflow: auto; }
    .voice-test-container .voice-test-form .form-field {
      margin-bottom: 1rem; }
      .voice-test-container .voice-test-form .form-field label {
        display: block; }
      .voice-test-container .voice-test-form .form-field input[type="number"], .voice-test-container .voice-test-form .form-field input[type="text"], .voice-test-container .voice-test-form .form-field select, .voice-test-container .voice-test-form .form-field textarea {
        width: 100%; }
      .voice-test-container .voice-test-form .form-field textarea {
        height: 25vh;
        resize: none; }
  .voice-test-container .voice-test-response {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 1rem;
    max-width: 50%; }
    .voice-test-container .voice-test-response .voice-test-response-scrollable {
      background-color: lightgray;
      border: 1px solid gray;
      flex: 1 1;
      font-family: monospace;
      overflow: auto;
      padding: 0.5rem; }

.voice-test .clear-history-container {
  bottom: unset;
  left: unset;
  right: 16px;
  top: 16px; }
  .voice-test .clear-history-container button {
    background-color: lightgray; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/VoiceTest.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,WAAW,EAAA;EALb;IAQI,YAAY,EAAA;EARhB;IAYI,aAAa;IACb,SAAO;IACP,gBAAgB,EAAA;EAdpB;IAkBI,SAAO;IACP,YAAY;IACZ,cAAc,EAAA;IApBlB;MAuBM,mBAAmB,EAAA;MAvBzB;QA0BQ,cAAc,EAAA;MA1BtB;QA8BQ,WAAW,EAAA;MA9BnB;QAkCQ,YAAY;QACZ,YAAY,EAAA;EAnCpB;IAyCI,aAAa;IACb,SAAO;IACP,sBAAsB;IACtB,YAAY;IACZ,cAAc,EAAA;IA7ClB;MAgDM,2BAA2B;MAC3B,sBAAsB;MACtB,SAAO;MACP,sBAAsB;MACtB,cAAc;MACd,eAAe,EAAA;;AAKrB;EAEI,aAAa;EACb,WAAW;EACX,WAAW;EACX,SAAS,EAAA;EALb;IAQM,2BAA2B,EAAA","sourcesContent":[".voice-test-container {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: hidden;\n  width: 100%;\n\n  h1 {\n    margin: 1rem;\n  }\n\n  .voice-test-form-container {\n    display: flex;\n    flex: 1;\n    overflow: hidden;\n  }\n\n  .voice-test-form {\n    flex: 1;\n    margin: 1rem;\n    overflow: auto;\n\n    .form-field {\n      margin-bottom: 1rem;\n\n      label {\n        display: block;\n      }\n\n      input[type=\"number\"], input[type=\"text\"], select, textarea {\n        width: 100%;\n      }\n\n      textarea {\n        height: 25vh;\n        resize: none;\n      }\n    }\n  }\n\n  .voice-test-response {\n    display: flex;\n    flex: 1;\n    flex-direction: column;\n    margin: 1rem;\n    max-width: 50%;\n    \n    .voice-test-response-scrollable {\n      background-color: lightgray;\n      border: 1px solid gray;\n      flex: 1;\n      font-family: monospace;\n      overflow: auto;\n      padding: 0.5rem;\n    }\n  }\n}\n\n.voice-test {\n  .clear-history-container {\n    bottom: unset;\n    left: unset;\n    right: 16px;\n    top: 16px;\n\n    button {\n      background-color: lightgray;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
